
<div>
  <h2 class="widget__title">{{ $t('theme.header') }}</h2>
  <div v-for="mode in modes" :key="mode.id" class="ods-form-group">
    <input
      type="radio"
      :id="mode.id"
      class="ods-form-radio"
      name="radio-group"
      :checked="theme === mode.id"
      @click="setThemeMode(mode.id)"
    />
    <label class="ods-form-label" :for="mode.id">{{ $t(`theme.colors.${mode.id}`) }}</label>
  </div>
</div>
