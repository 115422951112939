
<div>
  <div class="action-bar">
    <label class="form-group--checkbox">
      <input v-model="showArchived" class="form__checkbox" type="checkbox" />
      <span class="form-label">{{ $t('admin.objects.showArchived') }}</span>
    </label>
  </div>

  <div v-if="activeItemRef" class="wrapper">
    <div class="miller">
      <div
        v-for="{
          type,
          heading,
          activeClass,
          selectedClass,
          items,
          icon,
          notSelected,
          nonexistent,
          addEvent,
          cyCreate,
        } in columns"
        :key="type"
        class="miller__col"
      >
        <div class="miller__col-heading">{{ heading }}</div>
        <empty-state v-if="notSelected" :icon="'arrow-left'" :heading="notSelected" />

        <ul v-else class="miller__list">
          <template v-if="!items.length && type === 'objective'">
            <empty-state v-if="!items.length && !isLoadingPeriod" :icon="'exclamation'" :heading="nonexistent" />
            <template v-if="isLoadingPeriod">
              <template v-for="index in 2">
                <content-loader-okr-row :key="`okr-row-objective-${index}`"></content-loader-okr-row>
              </template>
            </template>
          </template>

          <template v-else-if="!items.length && type === 'keyResult'">
            <empty-state
              v-if="!items.length && !isLoadingObjective && type === 'keyResult'"
              :icon="'exclamation'"
              :heading="nonexistent"
            />
            <template v-if="isLoadingObjective">
              <template v-for="index in 3">
                <content-loader-okr-row :key="`okr-row-objective-${index}`"></content-loader-okr-row>
              </template>
            </template>
          </template>

          <template v-else>
            <li v-for="{ id, name, archived } in items" :key="id" class="miller__list-item">
              <router-link
                class="miller__link"
                :to="{ name: 'ItemAdminOKRs', query: { type, id } }"
                :class="{
                  active: activeClass(id),
                  selected: selectedClass(id),
                }"
              >
                <i class="miller__icon fa" :class="icon" />
                <span class="miller__label">{{ name }}</span>
                <span v-if="archived" class="miller__archived fa fa-file-archive"></span>
              </router-link>
            </li>
          </template>
        </ul>
        <button
          v-if="!notSelected"
          class="miller__add btn btn--ter btn--icon btn--fw"
          :data-cy="cyCreate"
          @click="addEvent"
        >
          <i class="icon fa fa-plus" />
          <span>{{ $t('btn.create') }}</span>
        </button>
      </div>
    </div>

    <component :is="editForm" :data="editObject"></component>
  </div>
</div>
