
<div>
  <site-header :toggle-show-aside-left="toggleShowAsideLeft" :toggle-show-aside-right="toggleShowAsideRight" />
  <slider-container
    :is-open="showAsideLeft"
    :toggle="toggleShowAsideLeft"
    :should-slide-in-from-left="true"
    :has-primary-background="true"
  >
    <site-sidebar :handle-navigation="toggleShowAsideLeft" />
  </slider-container>
  <slider-container
    v-if="user"
    :is-open="showAsideRight"
    :toggle="toggleShowAsideRight"
    :should-slide-in-from-left="false"
  >
    <user-profile-menu :id="user.id" />
  </slider-container>
  <v-spinner v-if="loading" />
  <router-view v-else></router-view>
</div>
