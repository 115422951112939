
<div>
  <div v-if="kpis.length && kpis.length < 3">
    <button class="btn btn--ghost" @click="showAddKPIModal = true">{{ $t('kpi.add') }}</button>
  </div>
  <div class="empty">
    <empty-state
      v-if="!kpis.length"
      :icon="'lightbulb'"
      :heading="$t('empty.noKPIs.heading')"
      :body="$t('empty.noKPIs.body')"
    >
      <button class="btn btn--ter" @click="showAddKPIModal = true">{{ $t('kpi.add') }}</button>
    </empty-state>
  </div>
  <div v-if="kpis.length" class="kpis">
    <div v-for="kpi in kpis" :key="kpi.id" :class="{ 'kpi--error': !!kpi.error }">
      <ItemAdminKPI :kpi="kpi" />
    </div>
  </div>

  <add-kpi-modal v-if="showAddKPIModal" @close="showAddKPIModal = false" />
</div>
