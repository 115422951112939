
<div class="container">
  <div class="home-widget">
    <section class="widget">
      <header class="widget__header">
        <span class="widget__title">{{ appOwner }}</span>
        <button
          v-tooltip="isOpen ? $t('btn.minimize') : $t('btn.expand')"
          class="widget__toggle fas fa-fw"
          :class="isOpen ? 'fa-minus' : 'fa-plus'"
          @click="toggleCollapse"
        />
      </header>
      <div v-if="isOpen" class="widget__body">
        <ul>
          <li v-for="org in tree" :key="`${org.id}-check`" style="margin-bottom: 1rem">
            <div class="ods-form-group">
              <input
                :id="org.id"
                type="checkbox"
                class="ods-form-radio"
                :checked="getCollapse('organization', org.slug)"
                @click="toggle('organization', org.slug)"
              />
              <label class="ods-form-label" :for="org.id">
                {{ org.name }}
                <span v-if="org.children.length"> ({{ org.children.length }}) </span>
              </label>
            </div>
            <ul v-if="getCollapse('organization', org.slug)">
              <li v-for="dept in org.children" :key="`${dept.id}-check`">
                <div class="ods-form-group indent">
                  <input
                    :id="dept.id"
                    type="checkbox"
                    class="ods-form-radio"
                    :checked="getCollapse('department', dept.slug)"
                    @click="toggle('department', dept.slug)"
                  />
                  <label class="ods-form-label" :for="dept.id">
                    {{ dept.name }}
                  </label>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>

  <ul v-if="user" class="home">
    <li v-if="!hasCheckedOrganizations" class="tree empty-state">
      {{ $t('general.emptyHome') }}
    </li>
    <template v-for="org in tree">
      <li v-if="getCollapse('organization', org.slug)" :key="org.id" class="tree">
        <item-row :data="org" class="tree__organization" type="organization"></item-row>
        <ul>
          <template v-for="dept in org.children">
            <li v-if="getCollapse('department', dept.slug)" :key="dept.id" class="card">
              <item-row :data="dept" type="department"></item-row>
              <ul>
                <li v-for="prod in dept.children" :key="prod.id">
                  <item-row :data="prod" type="product"></item-row>
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </li>
    </template>
  </ul>
</div>
