<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" data-category="ui" viewBox="0 0 32 32" width="32" height="32">
    <path fill="#fff" d="M2 6h28v2H2V6Zm0 9h28v2H2v-2Zm0 9h28v2H2v-2Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconMenu',
};
</script>
