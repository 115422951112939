
<header class="siteHeader">
  <button class="btn btn--ter btn-pri" @click="toggleShowAsideLeft">
    <icon-menu />
  </button>
  <h1 v-if="title" class="siteHeader__title">
    {{ title }}
  </h1>
  <button v-if="user" class="btn btn--ter btn--pri" @click="toggleShowAsideRight">
    <icon-user />
  </button>
</header>
