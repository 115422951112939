
<div v-if="activeItem" class="wrapper">
  <archived-restore v-if="activeItem.archived" :restore="restore" />

  <validation-observer v-slot="{ handleSubmit }">
    <form id="update-item" @submit.prevent="handleSubmit(update)">
      <form-component
        v-model="activeItem.name"
        input-type="input"
        name="name"
        :label="$t('fields.name')"
        rules="required"
        type="text"
        data-cy="org-name"
      />

      <label class="form-group">
        <span class="form-label">{{ $t('fields.slug') }}</span>
        <input v-model="activeItem.slug" class="form__field" type="text" disabled />
      </label>

      <form-component
        v-model="activeItem.missionStatement"
        input-type="textarea"
        name="missionStatement"
        :label="$t('fields.missionStatement')"
        rules="required"
      />

      <div v-if="type === 'department'" class="form-group">
        <span class="form-label">{{ $t('admin.department.parentOrganisation') }}</span>
        <v-select
          v-model="activeItem.organization"
          label="name"
          :options="organizations"
          :clearable="false"
        />
      </div>

      <div v-else-if="type === 'product'" class="form-group">
        <span class="form-label">{{ $t('admin.product.parentDepartment') }}</span>
        <v-select
          v-model="activeItem.department"
          label="name"
          :options="departments"
          :clearable="false"
        ></v-select>
      </div>

      <div class="form-group">
        <span class="form-label">{{ $t('general.teamMembers') }}</span>
        <v-select
          v-model="activeItem.team"
          multiple
          :options="users"
          :get-option-label="(option) => option.displayName || option.id"
        >
          <template #option="option">
            {{ option.displayName || option.id }}
            <span v-if="option.displayName !== option.id">({{ option.id }})</span>
          </template>
        </v-select>
      </div>

      <label class="form-group">
        <span class="form-label">{{ $t('fields.secret') }}</span>
        <span class="form-help" v-html="$t('admin.apiSecret')"></span>
        <input v-model="activeItem.secret" type="text" class="form__field" />
      </label>
    </form>
  </validation-observer>

  <div class="button-row">
    <button v-if="!activeItem.archived" class="btn btn--icon btn--archive" :disabled="loading" @click="archive">
      <i class="icon fa fa-fw fa-trash" />
      {{ $t('btn.delete') }}
    </button>
    <button class="btn btn--icon btn--pri btn--icon-pri" form="update-item" :disabled="loading">
      <i class="icon fa fa-fw fa-save" />
      {{ $t('btn.saveChanges') }}
    </button>
  </div>
</div>
