
<div v-if="user" class="modal__main--flex">
  <div class="column">
    <h2 class="widget__title title-2">{{ $t('user.profile') }}</h2>
    <validation-observer v-slot="{ handleSubmit }">
      <form class="form-group" id="updateUser" @submit.prevent="handleSubmit(save)">
        <span class="profileModal__label">{{ $t('fields.name') }}</span>
        <input v-model="thisUser.displayName" rules="required" class="form__field" />
      </form>
      <label class="form-group">
        <span class="profileModal__label">{{ $t('user.position.title') }}</span>
        <v-select
          v-model="thisUser.position"
          :options="jobPositions"
          :class="{'mandatory' : thisUser.position == null}"
          :get-option-label="(option) => $t(`user.position.${option}`)"
        >
        </v-select>
        <span v-if="thisUser && thisUser.position == null" class="profileModal__label profileModal__required">
          {{ $t('validation.required') }}
        </span>
      </label>
      <label class="form-group">
        <span class="profileModal__label">{{ $t('user.language') }}</span>
        <v-select
          v-model="thisUser.preferences.lang"
          :options="languages"
          :get-option-label="(option) => $t(`languages.${option}`)"
        >
        </v-select>
      </label>
    </validation-observer>

    <button
      class="btn btn--icon btn--pri btn--icon-pri profileModal__save-button"
      form="updateUser"
      :disabled="loading"
    >
      <span class="icon fa fa-fw fa-save"></span> {{ $t('btn.save') }}
    </button>

    <hr class="divider" />

    <h3 class="widget__title title-2">{{ $t('user.myProducts') }}</h3>

    <ul v-if="products.length > 0">
      <li v-for="product in products" :key="product.id">
        <div class="profileModal__info">
          <h2 class="title-2">{{ product.department.name }}</h2>
          <div>{{ product.name }}</div>
        </div>
      </li>
    </ul>

    <hr class="divider" />

    <h3 class="widget__title title-2">{{ $t('user.access') }}</h3>

    <div class="profileModal__info" v-if="user.superAdmin">
      <h2 class="title-2">{{ $t('user.superAdmin') }}</h2>
      <div>{{ $t('user.hasSuperAdmin') }}</div>
    </div>

    <div class="profileModal__info" v-if="user.admin && user.admin.length > 0">
      <h2 class="title-2">{{ $t('user.admin') }}</h2>
      <div>{{ $t('user.hasAdmin') }}</div>
    </div>

    <hr class="divider" />

    <theme-toggle />

    <hr class="divider" />

    <h3 class="widget__title title-2">{{ $t('general.administration') }}</h3>

    <div class="sidebar__group sidebar__bottom button-col">
      <router-link v-if="user.admin" :to="{ name: 'Admin' }" class="btn btn--ter button-link">
        <span>{{ $t('general.admin') }}</span>
      </router-link>
      <router-link :to="{ name: 'Help' }" class="btn btn--ter button-link">
        <span>{{ $t('general.help') }}</span>
      </router-link>
      <router-link :to="{ name: 'Api' }" class="btn btn--ter button-link">
        <span>{{ $t('general.api') }}</span>
      </router-link>
      <button class="btn btn--ter btn--icon btn--icon-pri button-link" @click="signOut">
        <span class="">{{ $t('general.signOut') }}</span>
      </button>
    </div>
  </div>
</div>
