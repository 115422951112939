
<div :class="{ overlay: isOpen }" @click.self="toggle">
  <transition :name="transitionName">
    <aside
      v-if="isOpen"
      class="sliderContainer"
      :class="{
        'sliderContainer--shouldSlideInFromLeft': shouldSlideInFromLeft,
        'sliderContainer--hasPrimaryBackground': hasPrimaryBackground,
      }"
    >
      <div class="sliderContainer__closeButtonContainer">
        <button
          class="btn"
          :class="{ 'btn--pri': hasPrimaryBackground, 'btn--ter': !hasPrimaryBackground }"
          @click.stop="toggle"
        >
          <icon-close :width="22" :height="22" :fill="iconFillColor" />
        </button>
      </div>
      <div class="sliderContainer__content">
        <slot />
      </div>
    </aside>
  </transition>
</div>
